import React, {useState} from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import NavMenu from "./navmenu"
import HeaderSocial from "./header-social"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Scrollspy from "react-scrollspy"
import { navigate } from "gatsby"

export default () => {
  const [isVisible, setVisibility] = useState(false)

  const { menuItems } = useSiteMetadata()


  let showStyle = null
  if (isVisible){
    showStyle = {transform: 'scaleY(1)'}
  }else{
    showStyle = null
  }

  return(
    <nav id="nav-wrap">
      <button
        id="toggle-btn"
        href="/#"
        title="Menu"
        onClick={() => setVisibility(!isVisible)}
        >
        <FontAwesomeIcon icon={ faBars } />
      </button>

      <Scrollspy
        className="nav mobile"
        style={showStyle}
        items={ menuItems.map(a => a.path) }
        currentClassName="current"
        offset={-100} >

        <li key="home"><button onClick={() => {
          navigate("/")
        }}>Home</button></li>

      </Scrollspy>
    </nav>
  )
}