import React , {useState} from "react"
import PostLink from "../components/post-link"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import Subscribe from "../components/subscribe"
import BlogHeader from "../components/blogHeader"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import HeaderSocial from "../components/header-social"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Scrollspy from "react-scrollspy"
import { navigate } from "gatsby"
import { graphql } from 'gatsby'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return(
  <div>
    <>
    <header>
      <div className="logo">
         <AnchorLink to="/#top">
           <img alt="" src={ useSiteMetadata().logo } />
         </AnchorLink>
      </div>
    <BlogHeader />
      <HeaderSocial />

    </header>
    <Layout>

        {/* Style Guide Section
        ================================================== */}
        <section id="styles"
          style= {{
            padding: '90px 0 72px',
            background: '#fff'
          }}
        >
          <div className="row section-head">
            <h1>Kagan Lending Blog</h1>
          </div> {/* Row End*/}
           <div className="row add-bottom">

              <div className="add-bottom">
                {Posts}
                 </div></div>
                 </section>
                 <Subscribe />


    </Layout>
  </>
  </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`

